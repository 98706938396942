.message {
  overflow: auto;
  background: none !important;
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 0 !important;
  font-size: 1.1rem;

  :first-child {
    color: white;
    margin: 0 5px;
    border-radius: 5px;
    padding: 0 5px;
    word-wrap: break-word;
  }

  &.left {
    display: flex;
    justify-content: flex-start;
    width: 90%;

    :first-child {
      background-color: gray;
    }
  }

  &.right {
    display: flex;
    justify-content: flex-end;

    :first-child {
      background-color: #2979ff;
    }
  }
}
