.chat {
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background: #eeeeee;
  flex-direction: column;
  margin: 0 auto;
  border: 5px solid gainsboro;
}
