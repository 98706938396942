.room {
  width: 80%;
  margin: 0 auto;

  .container-1 {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e3e3e3;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 10px;

    a {
      text-decoration: none;
      font-weight: bold;
    }
  }
}
