.container {
  max-width: 780px;
  height: 100%;
  text-align: center;
  padding-top: 20px;

  .room-container {
    height: 50vh;
    width: 80%;
    overflow: auto;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .room-container {
      width: 100%;
    }
  }
}
