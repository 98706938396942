.join {
  max-width: 600px;
  margin: auto;
  padding: 20vh 0 0 0;

  .field,
  button {
    margin: 20px;
  }
}
