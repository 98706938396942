.roominfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: #2979ff;
  border-radius: 4px 4px 0 0;
  width: 100%;

  .leftInnerContainer,
  .rightInnerContainer {
    display: flex;
    flex: 1;
  }

  .leftInnerContainer {
    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #4ef031;
      margin: auto 0;
      margin-right: 5px;
    }
    margin-left: 5%;
  }

  .rightInnerContainer {
    a {
      text-decoration: none;
      color: #eeeeee;
    }
    margin-right: 5%;
    justify-content: flex-end;
  }
}
